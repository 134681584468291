

<script>

/* eslint-disable */

var loadUser = function loadUser () {
  return new Promise((resolve) => {
    teamsnap.loadCollections((err) => {
      if (err) {
        console.error(err)
      }
      teamsnap.loadMe().then((users) => {
        resolve(users)
      })
    })
  })
}

var loadTeams = function loadTeams () {
  return new Promise((resolve) => {
    teamsnap.loadCollections((err) => {
      if (err) {
        console.error(err)
      }
      teamsnap.loadTeams().then((teams) => {
        resolve(teams)
      })
    })
  })
}

var loadTeam = function loadTeam (teamId) {
  return new Promise((resolve) => {
    teamsnap.loadCollections((err) => {
      if (err) {
        console.error(err)
      }
      teamsnap.loadTeam(teamId).then((team) => {
        resolve(team)
      })
    })
  })
}

var loadMembers = function loadMembers (teamId) {
  return new Promise((resolve) => {
    teamsnap.loadCollections((err) => {
      if (err) {
        console.error(err)
      }
      teamsnap.loadMembers(teamId).then((members) => {
        resolve(members)
      })
    })
  })
}

var loadUsers = function loadUsers (teamId) {
  return new Promise((resolve) => {
    teamsnap.loadCollections((err) => {
      if (err) {
        console.error(err)
      }
      teamsnap.loadUsers(teamId).then((users) => {
        resolve(users)
      })
    })
  })
}

import { store } from '../store'

export default {
  name: 'teamsnap',
  data () {
    return {
      isManager: false,
      tsUser: {},
      teamInfo: {},
      teamList: [],
      userProfile: store.state.userProfile
    }
  },
  created () {
    // Load Teamsnap SDK
    if (process.env.VUE_APP_TS_ENABLED === 'TRUE') {
      require('../teamsnap/teamsnap.min.js')
    } else {
      if (process.env.NODE_ENV === 'development') {
        this.isManager = true
      } else {
        this.isManager = false
      }
    }
  },
  methods: {
    loginTeamSnap () {
      if (teamsnap.hasSession()) {
        teamsnap.auth()
        this.$router.replace("teamlist");
      } else {
        let redirect = process.env.VUE_APP_TS_REDIRECT
        let scopes = process.env.VUE_APP_TS_SCOPES
        teamsnap.init(process.env.VUE_APP_TS_CLIENTID)
        teamsnap.startBrowserAuth(redirect, scopes, (err) => {
          if (err) {
            console.err(err)
          } else {
            this.$router.replace("teamlist");
          }
        })
      }
    },
    userIsManager (teamId) {
      if (this.userProfile.role === 'admin') {
        this.isManager = true
        return true
      }
      if (this.user.managedTeamIds.includes(parseInt(teamId))) {
        this.isManager = true
        return true
      } else {
        this.isManager = false
        return false
      }
    },
    setTeamInfo (team) {
      this.teamInfo = team
    },
    getTeamInfo (teamId) {
      let setTeamInfo = this.setTeamInfo
      loadTeam(teamId).then((team) => {
        setTeamInfo(team)
      })
    },
    getUserInfo () {
      return new Promise((resolve, reject) => {
        if (teamsnap.hasSession()) {
          teamsnap.auth()
          loadUser().then((user) => {
            // Add tsUserId to profile?
            resolve(user)
          })
        } else {
          let redirect = process.env.VUE_APP_TS_REDIRECT
          let scopes = process.env.VUE_APP_TS_SCOPES
          this.performAuth()
        }
      })
    },
    isMobile() {
      if(/Android|webOS|iPhone||iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    performAuth () {
      let headsUp = confirm("We need you to sign into TeamSnap.  It will send you right back!")
      if (headsUp) {
        let url = 'https://auth.teamsnap.com/oauth/authorize'
        let clientId = process.env.VUE_APP_TS_CLIENTID
        let redirect = process.env.VUE_APP_TS_REDIRECT + '?path=' + this.$route.path
        let scopes = process.env.VUE_APP_TS_SCOPES
        let responseType = 'token'
        let uri = url + '?client_id=' + clientId + '&redirect_uri=' + redirect + '&scopes=' + scopes + '&response_type=' + responseType
        if (this.$route.name === 'TeamDetail') {
          localStorage.setItem('doReload', 1)
        }
        window.location = uri;
      } else {
        localStorage.setItem('doReload', 0)
      }
    },
    updateAuthInfo (message) {
      if (message.data.type === 'auth') {
        var token = message.data.token
        sessionStorage.setItem('teamsnap.authToken', token)
        this.$router.go()
      }
    },
    getTeams (userId) {
      return new Promise ((resolve) => {
        this.getUserInfo().then(() => {
          loadTeams(userId).then((teams) => {
            let activeTeams = []
            teams.forEach(team => {
              if (!team.isArchivedSeason) {
                activeTeams.push(team)
              }
            })
            resolve(activeTeams)
          })
        })
      })
    },
    getTeamDetails (teamId) {
      return new Promise((resolve, reject) => {

        this.getUserInfo().then(tsUser => {

          if (tsUser.managedTeamIds.includes(teamId)) {
            let team = {
              players: [],
              members: [],
              games: []
            }
            teamsnap.loadCollections((err) => {
              if (err) {
                console.error('Error loading TeamSnap SDK')
              }
              teamsnap.loadTeam(teamId).then((teamData) => {
                team.tsId = teamData.id
                team.leagueName = teamData.leagueName
                team.tsName = teamData.name
                team.name = teamData.name
                team.createdDate = teamData.createdAt
                teamsnap.loadEvents(team.tsId).then((events) => {
                  this.missingGames = true
                  events.forEach((g, key) => {
                    if (g.isGame) {
                      let game = {
                        endDate: g.endDate,
                        gameLabel: g.label,
                        gameType: g.gameType,
                        location: g.locationName,
                        opponentName: g.opponentName,
                        startDate: g.startDate,
                        tsGameId: g.id,
                        tsOpponentId: g.opponentId
                      }
                      this.missingGames = false
                      team.games.push(game)
                    }
                  })
                  teamsnap.loadMembers(teamId).then((members) => {
                    members.forEach((p, key) => {
                      if (p.firstName) {
                        var firstName = p.firstName.charAt(0).toUpperCase() + p.firstName.slice(1).toLowerCase()
                      } else {
                        var firstName = ''
                      }
                      if (p.lastName) {
                        var lastName = p.lastName.charAt(0).toUpperCase() + p.lastName.slice(1).toLowerCase()
                      } else {
                        var lastName = ''
                      }
                      let jerseyNumber = parseInt(p.jerseyNumber)
                      if (isNaN(jerseyNumber)) {
                        jerseyNumber = null
                      }
                      if (!p.isNonPlayer) {
                        var hasJerseyNumber = false
                        var missingGoalie = true
                        this.goalieCount = 0
                        if (jerseyNumber) {
                          hasJerseyNumber = true
                        } else {
                          this.missingJerseyNumber = true
                        }
                        if (p.position === 'Goalie') {
                          missingGoalie = false
                          this.goalieCount++
                        }
                        var player = {
                          name: firstName + ' ' + lastName,
                          firstName: firstName,
                          lastName: lastName,
                          tsMemberId: p.id,
                          jerseyNumber: jerseyNumber,
                          tsUserId: p.userId,
                          hasJerseyNumber: hasJerseyNumber,
                          // photoHref: p.links.memberPhoto.href,
                          position: p.position
                        }
                        team.players.push(player)
                      } else {
                        var member = {
                          firstName: firstName,
                          lastName: lastName,
                          tsUserId: p.userId,
                          email: p.emailAddresses[0],
                          isManager: p.isManager
                        }
                        // team.members.push(member)
                      }
                    })
                    resolve(team)
                  })
                })
              })
            })
          } else {
            console.log("Sorry, you're not a manager of this team.")
            reject({error: 'notManager'})
          }
        })
      })
    },
    syncTsData(currentTeam, tsTeamData) {
      return new Promise ((resolve, reject) => {
        if (currentTeam.tsId) {
          let existingPlayers = []
          var newPlayerFound = 0
          var newGameFound = false
          let existingGames = []
          let newGames = []
          let removedGames = []
          currentTeam.players.forEach((player) => {
            existingPlayers.push(player.tsMemberId)
          })
          currentTeam.games.forEach((game) => {
            existingGames.push(game.tsGameId)
          })
          tsTeamData.players.forEach((p) => {
            // If the player does not exist, add
            if (!existingPlayers.includes(p.tsMemberId)) {
              currentTeam.players.push(p)
              newPlayerFound = true
            } else {
              // If the player does exist (existingPlayer ep), check if the info has changed
              currentTeam.players.forEach((ep, index) => {
                if (ep.tsMemberId === p.tsMemberId) {
                  if (ep.firstName != p.firstName) {
                    // Update the name and mark as updated
                    currentTeam.players[index].firstName = p.firstName
                    newPlayerFound = true
                  }
                  if (ep.lastName != p.lastName) {
                    // Update the name and mark as updated
                    currentTeam.players[index].lastName = p.lastName
                    newPlayerFound = true
                  }
                  let jerseyNumber = parseInt(p.jerseyNumber)
                  if (parseInt(ep.jerseyNumber) != jerseyNumber && Number.isInteger(jerseyNumber)) {
                    // Update the number and mark as updated
                    currentTeam.players[index].jerseyNumber = p.jerseyNumber
                    if (p.jerseyNumber) {
                      currentTeam.players[index].missingJerseyNumber = false
                    } else {
                      currentTeam.players[index].missingJerseyNumber = true
                    }
                    newPlayerFound = true
                  }
                  if (ep.position != p.position && p.position !== null && p.position !== '') {
                    // Update the position and mark as updated
                    currentTeam.players[index].position = p.position
                    if (p.position) {
                      currentTeam.players[index].missingPlayerPosition = false
                    } else {
                      currentTeam.players[index].missingPlayerPosition = true
                    }
                    newPlayerFound = true
                  }
                }
              })
            }
          })
          console.log(tsTeamData.games)
          tsTeamData.games.forEach((g) => {
            // Store the gameId for comparison later
            newGames.push(g.tsGameId)
            // If the game does not exist, add
            if (!existingGames.includes(g.tsGameId)) {
              currentTeam.games.push(g)
              newGameFound = true
            }
            // If the game does exist (existingGame eg), check if any of the deets changed
            currentTeam.games.forEach((eg, index) => {
              if (eg.tsGameId === g.tsGameId) {
                if (eg.gameLabel != g.gameLabel) {
                  // Update the label and mark as updated
                  currentTeam.games[index].gameLabel = g.gameLabel
                  newGameFound = true
                }
                if (eg.location != g.location) {
                  // Update the location and mark as updated
                  currentTeam.games[index].location = g.location
                  newGameFound = true
                }
                if (eg.opponentName != g.opponentName) {
                  // Update the opponentName and mark as updated
                  currentTeam.games[index].opponentName = g.opponentName
                  newGameFound = true
                }
                if (eg.tsOpponentId != g.tsOpponentId) {
                  // Update the tsOpponentId and mark as updated
                  currentTeam.games[index].tsOpponentId = g.tsOpponentId
                  newGameFound = true
                }
                // Need to figure out date comparison
                if (eg.startDate != g.startDate) {
                  // Update the startDate and mark as updated
                  // this.team.games[index].startDate = g.startDate
                  // newGameFound = true
                }
              }
            })
          })
          //If an existing game isn't in the new game list, it was deleted, so remove it
          currentTeam.games.forEach((g, i) => {
            if (!newGames.includes(g.tsGameId)) {
              removedGames.push(g.tsGameId)
            }
          })
          removedGames.forEach(rg => {
            let removedGameIndex = currentTeam.games.findIndex(function(game) {
              return game.tsGameId === rg
            })
            let removedGame = currentTeam.games.splice(removedGameIndex, 1)
            console.log('Removed game: ', removedGame[0].tsGameId)
            newGameFound = true
          })
          if (newGameFound || newPlayerFound) {
            localStorage.setItem('doReload', 0)
            store.commit('setCurrentTeam', currentTeam)
            store.dispatch('saveTeam').then(() => {
              resolve('Team Updated.')
            })
          } else {
            localStorage.setItem('doReload', 0)
            console.log('No new team data found.')
            resolve('No new team data found.')
          }
        } else {
          localStorage.setItem('doReload', 0)
          console.error('Team does not have a tsId')
          resolve('Team does not have a tsId')
        }
      })
    }
  }
}

// if (this.$route.params.teamId) {
//               this.userIsManager(this.$route.params.teamId)
//               loadMembers(this.$route.params.teamId).then((members) => {
//                 if (members.length === 0) {
//                   reject('Could not find members.')
//                 } else {
//                   members.forEach(member => {
//                     if (member.userId === user.id) {
//                       this.isMember = true
//                     }
//                   })
//                 }
//               })
//               loadUsers(this.$route.params.teamId).then((users) => {
//                 if (users.length === 0) {
//                   console.log('Could not find users.')
//                 } else {
//                   users.forEach(tsUser => {
//                     if (tsUser.id === user.id) {
//                       this.isMember = true
//                     }
//                   })
//                 }
//                 resolve()
//               })
//             } else {
//               resolve()
//             }

</script>
